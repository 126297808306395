<template>
    <div class = "col-right">
        <a-card title = "预付款余额(元)">
            <div class = "row-info ng-star-inserted">
                <div class = "col-total"><i>¥ </i><span>{{ advance }}</span></div>
                <div class = "col-btn">
                    <a-button @click = "() => {showRechargeModal = true}">充值</a-button>
                </div>
                <div class = "col-btn">
                    <a-button @click = "() => {showWithdrawalModal = true;}">提现</a-button>
                </div>
                <div class = "col-btn">
                    <a-button @click="() => {showBalanceWarningModal = true;}">预警金额设置</a-button>
                </div>
            </div>
        </a-card>
        <a-card>

            <a-tabs :tabBarGutter = "0" @change = "changeTable">
                <a-tab-pane key = "1" tab = "余额记录">
                </a-tab-pane>
                <a-tab-pane key = "2" tab = "充值记录">
                </a-tab-pane>
                <a-tab-pane key = "3" tab = "提现记录">
                </a-tab-pane>
                <a-button slot = "tabBarExtraContent">导出</a-button>
<!--                <a-button slot = "tabBarExtraContent" style = "margin-left: 8px">查看导出记录</a-button>-->
            </a-tabs>
            <a-form v-show="tableIdx == 1" layout = "inline">
                <a-form-item label = "业务单号">
                    <a-input v-model="balanceQuery.trade_order_no" />
                </a-form-item>
                <a-form-item label = "交易单号">
                    <a-input v-model="balanceQuery.order_no" />
                </a-form-item>
                <a-form-item label = "交易类型">
                    <a-select v-model="balanceQuery.type" style = "width: 180px" allowClear>
                        <a-select-option v-for = "item in formTypeList"
                                         :key = "item.value">{{ item.text }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label = "外部订单号">
                    <a-input />
                </a-form-item>
                <a-form-item label = "收支类型">
                    <a-select v-model="balanceQuery.income_type" style = "width: 180px" allowClear>
                        <a-select-option v-for = "item in inOrOutList"
                                         :key = "item.value">{{ item.text }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item
                    label = "交易时间"
                >
                    <a-range-picker v-model="balanceQuery.rangeDate" style = "width: 252px"
                                    value-format = "YYYY-MM-DD"
                                    format = "YYYY-MM-DD"
                                    :locale = "locale">
                        <i slot = "suffixIcon"></i>
                    </a-range-picker>
                </a-form-item>
                <div>
                    <a-form-item>
                        <a-button type = "primary" @click="searchBalance">查询</a-button>
                        <a-button html-type = "reset" style = "margin-left: 8px">重置</a-button>
                    </a-form-item>
                </div>
            </a-form>
            <a-form v-show="tableIdx == 2" layout = "inline">
                <a-form-item label = "充值单号">
                    <a-input v-model="rechargeQuery.order_no" />
                </a-form-item>
                <a-form-item label = "支付方式">
                    <a-select v-model="rechargeQuery.pay_type" style = "width: 180px" allowClear>
                        <a-select-option v-for = "item in payTypeList"
                                         :key = "item.value">{{ item.text }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label = "充值状态">
                    <a-select v-model="rechargeQuery.status" style = "width: 180px" allowClear>
                        <a-select-option v-for = "item in rechargeStatusList"
                                         :key = "item.value">{{ item.text }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label = "充值时间">
                    <a-range-picker v-model="rechargeQuery.created_range_date" style = "width: 252px"
                                    value-format = "YYYY-MM-DD"
                                    format = "YYYY-MM-DD"
                                    :locale = "locale">
                        <i slot = "suffixIcon"></i>
                    </a-range-picker>
                </a-form-item>
                <a-form-item label = "完成时间">
                    <a-range-picker v-model="rechargeQuery.complete_range_date" style = "width: 252px"
                                    value-format = "YYYY-MM-DD"
                                    format = "YYYY-MM-DD"
                                    :locale = "locale">
                        <i slot = "suffixIcon"></i>
                    </a-range-picker>
                </a-form-item>
                <div>
                    <a-form-item>
                        <a-button type = "primary" @click="searchRecharge">查询</a-button>
                        <a-button html-type = "reset" style = "margin-left: 8px">重置</a-button>
                    </a-form-item>
                </div>
            </a-form>
            <a-form v-show="tableIdx == 3" layout = "inline">
                <a-form-item label = "提现单号">
                    <a-input v-model="withdrawalQuery.order_no" />
                </a-form-item>
                <a-form-item label = "提现状态">
                    <a-select v-model="withdrawalQuery.status" style = "width: 180px" allowClear>
                        <a-select-option v-for = "item in withdrawalStatusList"
                                         :key = "item.value">{{ item.text }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label = "申请时间">
                    <a-range-picker v-model="withdrawalQuery.created_range_date" style = "width: 252px"
                                    value-format = "YYYY-MM-DD"
                                    format = "YYYY-MM-DD"
                                    :locale = "locale">
                        <i slot = "suffixIcon"></i>
                    </a-range-picker>
                </a-form-item>
                <a-form-item label = "到账时间">
                    <a-range-picker v-model="withdrawalQuery.arrive_range_date" style = "width: 252px"
                                    value-format = "YYYY-MM-DD"
                                    format = "YYYY-MM-DD"
                                    :locale = "locale">
                        <i slot = "suffixIcon"></i>
                    </a-range-picker>
                </a-form-item>
                <div>
                    <a-form-item>
                        <a-button type = "primary" @click="searchWithdrawal">查询</a-button>
                        <a-button html-type = "reset" style = "margin-left: 8px">重置</a-button>
                    </a-form-item>
                </div>
            </a-form>
        </a-card>
        <a-card>
            <a-table v-if = "tableIdx == 1" :columns = "formColumns[1]" :data-source = "formDataList"
                     :pagination = "false">
                <div slot = "trade_order_no" slot-scope = "text, record">
                    <p>{{ record.order_no }}</p>
                    <p>{{ record.trade_order_no }}</p>
                </div>
                <div slot = "type" slot-scope = "text, record">
                    <p>{{ record.type_desc }}</p>
                    <p>{{ record.created_at }}</p>
                </div>
                <div slot = "action" slot-scope = "text, record, idx">
                    <a @click = "showTradeDetail(idx)" class = "primary-text-color">查看</a>
                </div>
            </a-table>
            <a-table v-if = "tableIdx == 2" :columns = "formColumns[2]" :data-source = "formDataList"
                     :pagination = "false">
                <div slot = "type" slot-scope = "text, record">
                    <p>{{ record.created_at }}</p>
                </div>
                <div slot = "action" slot-scope = "text, record, idx">
                    <a @click = "showRechargeDetail(idx)" class = "primary-text-color">查看</a>
                </div>
            </a-table>
            <a-table v-if = "tableIdx == 3" :columns = "formColumns[3]" :data-source = "formDataList"
                     :pagination = "false">
                <div slot = "bank_info" slot-scope = "text, record">
                    <p>{{ record.bank_card_no }}</p>
                    <p>{{ record.bank_branch_name }}</p>
                </div>
                <div slot = "type" slot-scope = "text, record">
                    <p>{{ record.created_at }}</p>
                </div>
                <div slot = "total" slot-scope = "text, record">
                    <p>{{ record.money }}</p>
                </div>
                <div slot = "action" slot-scope = "text, record, idx">
                    <a @click = "showWithdrawalDetail(idx)" class = "primary-text-color">查看</a>
                </div>
            </a-table>
        </a-card>
        <!--交易详情 start-->
         <a-modal
             :dialog-style = "{ top: '100px' }"
             width = "480px"
             :visible = "showTradeDetailModal"
             :footer = "false"
             @cancel = "() => {showTradeDetailModal = false}"
         >
            <div slot = "title" class = "text-center">
                <div class = "text-center">交易详情</div>
                <small class = "text-center">￥{{tradeDetail.amount}}</small>
            </div>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">收支类型:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.income_type == 1 ? '收入' : '支出' }}</a-col>
            </a-row>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">交易单号:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.order_no }}</a-col>
            </a-row>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">业务单号:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.trade_order_no }}</a-col>
            </a-row>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">交易时间:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.created_at }}</a-col>
            </a-row>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">余额:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.balance }}</a-col>
            </a-row>
             <a-row style="padding-bottom: 16px;">
                <a-col :span="5">备注:</a-col>
                <a-col :span="19" class="text-right">{{ tradeDetail.remark }}</a-col>
            </a-row>
        </a-modal>

        <!--交易详情 end-->
        <!--提现详情 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "480px"
            :visible = "showWithdrawalDetailModal"
            :footer = "false"
            @cancel = "() => {showWithdrawalDetailModal = false}"
        >
            <div slot = "title" class = "text-center">
                <div class = "text-center">提现详情</div>
                <small class = "text-center">￥{{withdrawalDetail.money}}</small>
                <p><small class = "text-center">{{withdrawalDetail.status_desc}}</small></p>
            </div>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">提现金额:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.money }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">手续费:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.charge }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">共计:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.money }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">提现单号:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.order_no }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">申请时间:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.created_at }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">审核时间:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.validate_at }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">到账时间:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.arrive_at }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">收款银行卡号:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.bank_card_no }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">开户行:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.bank_branch_name }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">收款帐户名:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.收款帐户名 }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">备注:</a-col>
                <a-col :span="19" class="text-right">{{ withdrawalDetail.remark }}</a-col>
            </a-row>
        </a-modal>
        <!--提现详情 end-->
        <!--充值详情 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "480px"
            :visible = "showRechargeDetailModal"
            :footer = "false"
            @cancel = "() => {showRechargeDetailModal = false}"
        >
            <div slot = "title" class = "text-center">
                <div class = "text-center">充值详情</div>
                <small class = "text-center">￥{{rechargeDetail.amount}}</small>
                <p><small class = "text-center">{{rechargeDetail.status_desc}}</small></p>
            </div>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">提现金额:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.amount }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">支付方式:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.pay_type_desc }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">充值单号:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.order_no }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">充值时间:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.created_at }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">完成时间:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.status == 0 ? '--' : rechargeDetail.updated_at }}</a-col>
            </a-row>
            <a-row style="padding-bottom: 16px;">
                <a-col :span="5">备注:</a-col>
                <a-col :span="19" class="text-right">{{ rechargeDetail.remark }}</a-col>
            </a-row>
        </a-modal>
        <!--充值详情 end-->

        <!--提现表单 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "620px"
            :visible = "showWithdrawalModal"
            :footer = "false"
            @cancel = "() => {showWithdrawalModal = false}"
        >
            <div slot = "title">
                <div class = "text-center" style = "font-size: 14px; font-weight: normal;">预付款提现</div>
            </div>

            <a-row>
                <a-col :span="20" :offset="4"><div class = "balance">余额：<span>￥{{ advance }}元</span></div></a-col>
            </a-row>
            <a-form :label-col = "{ span: 4 }" :wrapper-col = "{ span: 20 }" :form = "withdrawalForm"
                    @submit = "goWithdrawal">
                <a-form-item label = "提现金额">

                    <a-row :gutter = "8">
                        <a-col :span = "18">
                            <a-input-number style = "width: 100%;" placeholder = "请输入提现金额，至少1元" step = "0.01"
                                            :min = "1"
                                            v-decorator = "[
                                          'money',
                                          { rules: [{ required: true, message: '请输入提现金额，至少1元', type: 'number', min: 1}] },
                                        ]"
                            />
                        </a-col>
                        <a-col :span = "2">
                            元
                        </a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label = "手续费">
                    <div>0.00元（费率0%）</div>
                </a-form-item>
                <a-form-item label = "共计">
                    <div>{{ withdrawal.money || 0 }}元</div>
                </a-form-item>
                <a-form-item label = "收款帐户">
                    <a-row :gutter = "8">
                        <a-col :span = "18">
                            <a-select
                                v-decorator = "[
                  'bank_id',
                  { rules: [{ required: true, message: '请选择'}] },
                ]"
                                placeholder = "请选择"
                            >
                                <a-select-option v-for = "(item, idx) in bankList" :value = "item.id" :key = "idx">
                                    {{ item.bank_card_no }}({{ item.bank_branch_name }})
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>

                </a-form-item>
                <a-form-item
                    label = "验证码"
                    :extra = "verifyCodeExtra"
                >
                    <a-row :gutter = "8">
                        <a-col :span = "12">
                            <a-input
                                placeholder = "请输入短信验证码"
                                v-decorator = "[
                          'verify_code',
                          { rules: [{ required: true, message: '请输入短信验证码' }] },
                        ]"
                            />
                        </a-col>
                        <a-col :span = "12">
                            <a-button @click = "getVerifyCode(6)" :disable = "countDown < 60">{{ getVerifyCodeText }}
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form-item>
                <div class = "recharge-btn">
                    <a-button @click = "() => {showWithdrawalModal=false;}">取消</a-button>
                    <a-button type = "primary" style = "margin-left: 8px" html-type = "submit">确定申请提现</a-button>
                </div>
            </a-form>
        </a-modal>
        <!--提现表单 end-->
        <!--充值表单1 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "620px"
            :visible = "showRechargeModal"
            :footer = "false"
            @cancel = "() => {showRechargeModal = false}"
        >
            <div slot = "title">
                <div class = "text-center" style = "font-size: 14px; font-weight: normal;">预付款充值</div>
            </div>

            <a-row>
                <a-col :span="20" :offset="4"><div class = "balance">余额：<span>￥{{ advance }}元</span></div></a-col>
            </a-row>
            <div class = "recharge">
                <a-row>
                    <a-col :span="4">
                        <label>预警金额</label>
                    </a-col>
                    <a-col :span="18">
                        <a-input-number placeholder = "请输入充值金额，至少1元" step = "1" v-model = "money" :min = "0" />
                        <span>元</span>
                    </a-col>
                </a-row>
            </div>
            <div class = "recharge-btn">
                <a-button @click = "() => {showRechargeModal=false;}">取消</a-button>
                <a-button type = "primary" style = "margin-left: 8px" @click = "() => {goRecharge()}">立即支付</a-button>
            </div>
        </a-modal>
        <!--充值表单1 end-->
        <!--预警金额设置表单1 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "620px"
            :visible = "showBalanceWarningModal"
            :footer = "false"
            @cancel = "() => {showBalanceWarningModal = false}"
        >
            <div slot = "title">
                <div class = "text-center" style = "font-size: 14px; font-weight: normal;">预警金额设置</div>
            </div>
            <a-row>
                <a-col :span="20" :offset="4"><div class = "balance">余额：<span>￥{{ advance }}元</span></div></a-col>
            </a-row>

            <div class = "recharge">
                <a-row>
                    <a-col :span="4">
                        <label>预警金额</label>
                    </a-col>
                    <a-col :span="18">
                        <a-input-number placeholder = "请输入预警金额，至少1元" step = "1" v-model = "warning.balance" :min = "0" />
                        <span>元</span>
                    </a-col>
                </a-row>
            </div>
            <div class = "recharge">
                <a-row>
                    <a-col :span="4">
                        <label>预警手机号</label>
                    </a-col>
                    <a-col :span="18">
                        <a-input-number placeholder = "请输入预警手机号，用于发送短信通知" v-model = "warning.phone" />
                    </a-col>
                </a-row>
            </div>
            <div class = "recharge-btn">
                <a-button @click = "() => {showBalanceWarningModal=false;}">取消</a-button>
                <a-button type = "primary" style = "margin-left: 8px" @click = "() => {setWarning()}">确定</a-button>
            </div>
        </a-modal>
        <!--预警金额设置表单1 end-->
        <!--充值表单2 start-->
        <a-modal
            :dialog-style = "{ top: '100px' }"
            width = "900px"
            :visible = "showRechargeModal2"
            :footer = "false"
            @cancel = "() => {showRechargeModal2 = false}"
        >
            <div>
                <h3 class = "text-center">收银台</h3>
                <div class = "title">支付金额</div>
            </div>
            <div>
                <span class = "icon">￥</span>
                <span class = "money">{{ money }}</span>
            </div>
            <div class = "pay-box">
                <p class = "paychannel-title">支付方式</p>
                <a-radio-group button-style = "solid" v-model = "payType" @change = "changePayType">
                    <div class = "paychannel-box ng-star-inserted" style = "">
                        <div class = "paychannel-item ng-star-inserted">
                            <img width = "26" height = "26"
                                 src = "https://scce-cos-obs.obs.cn-south-1.myhuaweicloud.com:443/2ef5d1ff962b4ddea33e525b66447588.png" /><span>线下转账 </span>
                            <a-radio :value = "1">
                            </a-radio>
                        </div>
                        <div class = "paychannel-item ng-star-inserted">
                            <img width = "26" height = "26"
                                 src = "https://scce-COS-obs.obs.cn-south-1.myhuaweicloud.com:443/7023a7c58b18444ebd66bc774e853fe2.png" /><span>支付宝支付 </span>
                            <a-radio :value = "2">
                            </a-radio>
                        </div>
                    </div>
                </a-radio-group>
            </div>
            <div class = "mt-md" v-show = "payType == 2">
                <div
                    style = "border: 1px solid rgb(231, 231, 231); display: inline-block; height: 200px; width: 200px;">
                    <a-spin :spinning = "!QRImgUrl" style = "width: 100%; height:100%">
                        <img v-show = "QRImgUrl" style = "width: 100%; height: 100%;" :src = "QRImgUrl" alt = "">
                    </a-spin>
                </div>
                <div class = "scan-tip mt-md">
                    <div><img src = "../../assets/img/icons/scan.png" class = "mr-sm scan-icon"> 请使用支付宝扫一扫 扫描二维码支付</div>
                </div>
                <div class = "text-center mt-lg">
                    <a-button type = "danger">支付完成</a-button>
                </div>
            </div>
            <div class = "mt-lg" v-show = "payType == 1">
                <div class = "bankInfo">
                    <p class = "bank-title">官方收款银行账户</p>
                    <p><span class = "bank-number">{{ bankInfo.bank_card_no }}</span>
                        <span class = "copy ml-md">复制</span></p>
                    <p> 账户名：<span>{{ bankInfo.bank_account }}</span>
                        <span class = "copy ml-md">复制</span></p>
                    <p> 开户行：<span>{{ bankInfo.bank_name }}</span>
                        <span class = "copy ml-md">复制</span></p></div>

                <div class = "banktip mt-lg"><img
                    src = "../../assets/img/icons/warning.png"
                    class = "mr-sm scan-icon ml-lg mt-md"><span
                >请往官方收款银行账户汇入对应支付金额，汇入时请在“用途/备注/摘要栏”填写“订单号”，便于快速核销款项，请勿使 用支付宝/微信支付进行转账。</span>
                </div>

                <div class = "mt-lg bank-form">
                    <h3>填写凭证</h3>
                    <a-form :label-col = "{ span: 6 }" :wrapper-col = "{ span: 18 }" :form = "bankForm"
                            @submit = "offlineRecharge">
                        <a-row>
                            <a-col :span = "16">
                                <a-form-item label = "转帐银行卡号" required>
                                    <a-input
                                        placeholder = "请输入转账银行卡卡号"
                                        v-decorator = "[
                                            'bank_card_no',
                                            { rules: [
                                                {
                                                    required: true,
                                                    message: '请输入转账银行卡卡号'
                                                }
                                            ]}
                                        ]"
                                    />
                                </a-form-item>
                                <a-form-item label = "转帐帐户名" required>
                                    <a-input
                                        placeholder = "请输入转帐帐户名"
                                        v-decorator = "[
                                            'bank_account',
                                            { rules: [
                                                {
                                                    required: true,
                                                    message: '请输入转帐帐户名'
                                                }
                                            ]}
                                        ]"
                                    />
                                </a-form-item>
                                <a-form-item label = "转帐日期" required>
                                    <a-date-picker
                                        placeholder = "请选择日期"
                                        format = "YYYY-MM-DD"
                                        value-format = "YYYY-MM-DD"
                                        v-decorator = "['transfer_date',  {
                                            rules: [{  required: true, message: '请选择日期' }],
                                          }]"
                                    />
                                </a-form-item>
                                <a-form-item label = "转帐凭证" required>
                                    <a-upload
                                        name = "file"
                                        list-type = "picture-card"
                                        class = "avatar-uploader"
                                        :show-upload-list = "false"
                                        :action = "uploadUrl"
                                        :before-upload = "beforeUpload"
                                        @change = "uploadChange"
                                    >
                                        <img v-if = "imageUrl" :src = "imageUrl" style = "width: 100%; height: 100%;"
                                             alt = "avatar" />
                                        <div v-else>
                                            <a-icon :type = "uploadLoading ? 'loading' : 'plus'" />
                                            <div class = "ant-upload-text">
                                                Upload
                                            </div>
                                        </div>
                                    </a-upload>
                                </a-form-item>
                                <a-form-item style = "margin-left: 120px;">
                                    <div>图片支持jpg、jpeg、png格式</div>
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <div class = "modal-footer text-center">
                            <a-button type = "danger" html-type = "submit">提交审核</a-button>
                            <p class = "mt-md">审核时间1-3个工作日，审核确认到账即支付成功</p>
                        </div>
                    </a-form>
                </div>
            </div>
        </a-modal>
        <!--充值表单2 end-->
    </div>
</template>

<script>
    import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
    import QRCode from 'qrcode'
    import service from "../../../utils/request";
    // function getBase64(img, callback) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }
    export default {
        name: "property-prepay",
        data() {
            return {
                locale,
                uploadUrl: '',
                uploadLoading: false,
                imageUrl: '',
                showRechargeModal: false,
                showRechargeModal2: false,
                showTradeDetailModal: false,
                showWithdrawalModal: false,
                showRechargeDetailModal: false,
                showWithdrawalDetailModal: false,
                showBalanceWarningModal: false,
                tradeDetail:{},
                withdrawalDetail:{},
                rechargeDetail:{},
                payType: 0,
                money: '',
                warning:{
                    balance:0,
                    phone:''
                },
                QRImgUrl: '',
                QRlink: '',
                bankInfo: {},
                userInfo: JSON.parse(localStorage.getItem('userInfo')),
                advance: 0.00,
                withdrawal: {
                    money: '',
                    account: '',
                    verify_code: ''
                },
                verifyCodeExtra: '',
                getVerifyCodeText: '获取验证码',
                countDown: 60,
                formTypeList: [
                    {value: 0, text: '全部'},
                    {value: 1, text: '充值'},
                    {value: 2, text: '订单支付'},
                    {value: 3, text: '订单退款'},
                    {value: 4, text: '提现'},
                    {value: 5, text: '提现手续费'},
                    {value: 6, text: '提现失败返回'},
                    {value: 7, text: '提现失败手续费返回'},
                ],
                payTypeList: [
                    {value: 0, text: '全部'},
                    {value: 1, text: '线下转帐'},
                    {value: 2, text: '支付宝'},
                ],
                rechargeStatusList: [
                    {value: '', text: '全部'},
                    {value: 0, text: '充值中'},
                    {value: 1, text: '充值成功'},
                    {value: 2, text: '充值失败'},
                ],
                withdrawalStatusList: [
                    {value: '', text: '全部'},
                    {value: 0, text: '等待打款'},
                    {value: 1, text: '提现成功'},
                    {value: 2, text: '提现失败'},
                ],
                inOrOutList: [
                    {value: 0, text: '全部'},
                    {value: 1, text: '收入'},
                    {value: 2, text: '支出'},
                ],
                tableIdx: 1,
                formColumns: {
                    1: [
                        {
                            title: '交易单号|业务单号',
                            dataIndex: 'trade_order_no',
                            key: 'trade_order_no',
                            scopedSlots: {customRender: 'trade_order_no'},
                        },
                        {
                            title: '外部订单号',
                            dataIndex: 'third_order_no',
                            key: 'third_order_no',
                        },
                        {
                            title: '交易类型|交易时间',
                            dataIndex: 'type',
                            key: 'type',
                            scopedSlots: {customRender: 'type'},
                        },
                        {
                            title: '金额(元)',
                            dataIndex: 'amount',
                            key: 'amount',
                        },
                        {
                            title: '余额(元)',
                            dataIndex: 'balance',
                            key: 'balance',
                        },
                        {
                            title: '操作',
                            key: 'action',
                            scopedSlots: {customRender: 'action'},
                        },
                    ],
                    2: [
                        {title: '充值单号', dataIndex: 'order_no', key: 'order_no',},
                        {
                            title: '充值时间 | 完成时间',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            scopedSlots: {customRender: 'created_at'},
                        },
                        {title: '金额(元)', dataIndex: 'amount', key: 'amount', scopedSlots: {customRender: 'amount'},},
                        {
                            title: '支付方式',
                            dataIndex: 'pay_type_desc',
                            key: 'pay_type_desc',
                        },
                        {title: '状态', dataIndex: 'status_desc', key: 'status', scopedSlots: {customRender: 'status'},},
                        {title: '操作', dataIndex: 'action', key: 'action', scopedSlots: {customRender: 'action'},},
                    ],
                    3: [
                        {title: '提现单号', dataIndex: 'order_no', key: 'order_no',},
                        {
                            title: '申请时间 | 到账时间',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            scopedSlots: {customRender: 'created_at'},
                        },
                        {
                            title: '收款账户(卡号|开户行）',
                            dataIndex: 'bank_info',
                            key: 'bank_info',
                            scopedSlots: {customRender: 'bank_info'},
                        },
                        {title: '提现金额(元)', dataIndex: 'money', key: 'money'},
                        {title: '手续费(元)', dataIndex: 'charge', key: 'charge'},
                        {title: '共计(元)', dataIndex: 'total', key: 'total', scopedSlots: {customRender: 'total'}},
                        {title: '状态', dataIndex: 'status_desc', key: 'status'},
                        {title: '操作', dataIndex: 'action', key: 'action', scopedSlots: {customRender: 'action'},},
                    ]
                },
                formDataList: [],
                bankList: [],
                balanceQuery: {
                    trade_order_no:'',
                    order_no:'',
                    type:'',
                    third_order_no:'',
                    income_type:'',
                },
                rechargeQuery: {
                    order_no:'',
                    pay_type:'',
                    status:'',
                    created_range_date:[],
                    complete_range_date:[],
                },
                withdrawalQuery: {
                    order_no:'',
                    status:'',
                    created_range_date:[],
                    arrive_range_date:[],
                },
                page: 1,
                pageSize: 20
            }
        },

        beforeCreate() {
            this.bankForm = this.$form.createForm(this, {name: 'bankForm'});
            this.withdrawalForm = this.$form.createForm(this, {name: 'withdrawalForm'});
        },
        created() {
            this.uploadUrl = this.$apiBaseURL + '/api/index/uploadImg';
            this.getBankInfo();
            this.getAdvance();
            this.getBankAccountList();
            this.verifyCodeExtra = '为了账户安全，需您的手机验证(' + this.userInfo.mobile + ')';
            this.getBalanceList();
        },
        watch: {
            tableIdx(newData) {

                if (newData == 1) {
                    this.resetBalanceQuery();
                    this.getBalanceList();
                } else if (newData == 2) {
                    this.resetRechargeQuery();
                    this.getRechargeList();
                } else if (newData == 3) {
                    this.resetWithdrawalQuery();
                    this.getWithdrawalList();
                }
            }
        },
        methods: {
            setWarning(){
                this.spinning = true;
                service.post(service.uri.user.setBalanceWarning, this.warning).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.showBalanceWarningModal = false;
                    }
                });
            },
            searchBalance(){
                this.getBalanceList();
            },
            searchRecharge(){
                this.getRechargeList();
            },
            searchWithdrawal(){
                this.getWithdrawalList();
            },
            showTradeDetail(idx){
                this.tradeDetail = this.formDataList[idx];
                this.showTradeDetailModal = true;
            },
            showWithdrawalDetail(idx){
                this.withdrawalDetail = this.formDataList[idx];
                this.showWithdrawalDetailModal = true;
            },
            showRechargeDetail(idx){
                this.rechargeDetail = this.formDataList[idx];
                this.showRechargeDetailModal = true;
            },
            resetBalanceQuery() {
                this.balanceQuery = {};
                this.resetPage()
            },
            resetRechargeQuery() {
                this.rechargeQuery = {};
                this.resetPage()
            },
            resetWithdrawalQuery() {
                this.withdrawalQuery = {};
                this.resetPage()
            },
            resetPage() {
                this.page = 1;
            },
            getRechargeList() {
                let query = Object.assign(this.rechargeQuery, {page: this.page, pageSize: this.pageSize});
                service.post(service.uri.pay.rechargeList, query).then(res => {
                    if (res.code == 200) {
                        this.formDataList = res.data.list;
                    }
                });
            },
            getBalanceList() {
                let query = Object.assign(this.balanceQuery, {page: this.page, pageSize: this.pageSize});
                service.post(service.uri.pay.balanceLog, query).then(res => {
                    if (res.code == 200) {
                        this.formDataList = res.data.list;
                    }
                });
            },
            getWithdrawalList() {
                let query = Object.assign(this.withdrawalQuery, {page: this.page, pageSize: this.pageSize});
                service.post(service.uri.pay.getWithdrawalList, query).then(res => {
                    if (res.code == 200) {
                        this.formDataList = res.data.list;
                    }
                });
            },
            getBankAccountList() {
                service.post(service.uri.bankAccount.getList).then(res => {
                    if (res.code == 200) {
                        this.bankList = res.data.list;
                    }
                });
            },
            getVerifyCode(type) {
                if (this.countDown < 60) return false;
                service.post(service.uri.user.getVerifyCode, {phone: this.userInfo.mobile, type: type}).then(() => {

                    this.timer = setInterval(() => {
                        this.countDown--;
                        if (this.countDown <= 0) {
                            this.getVerifyCodeText = '获取验证码';
                            this.countDown = 60;
                            clearInterval(this.timer);
                            return;
                        }
                        this.getVerifyCodeText = '请等待' + this.countDown + 'S';
                    }, 1000);
                });
            },
            goWithdrawal(e) {
                e.preventDefault();
                this.withdrawalForm.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        console.log('Received values of form: ', values);
                        service.post(service.uri.pay.withdrawal, values).then(res => {
                            if (res.code == 200) {
                                this.$message.success("提现申请成功");
                                this.showWithdrawalModal = false;
                            }
                        });
                    }
                });
            },
            getAdvance() {
                service.post(service.uri.user.getAdvance).then(res => {
                    if (res.code == 200) {
                        this.advance = res.data
                    }
                });
            },
            changeTable(e) {
                this.tableIdx = e;
            },
            getBankInfo() {
                service.post(service.uri.pay.getBankInfo).then(res => {
                    if (res.code == 200) {
                        this.bankInfo = res.data
                    }
                });
            },
            changePayType(e) {
                if (e.target.value == 2) {
                    this.orderMainPay();
                }
            },
            orderMainPay() {
                service.post(service.uri.pay.orderMainPay, {amount: this.money, pay_type: this.payType}).then(res => {
                    this.QRlink = res.data.expend.qrcode_url;
                    this.getQRcode();
                });
            },

            offlineRecharge(e) {
                e.preventDefault();
                this.bankForm.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        console.log('Received values of form: ', values);
                        let data = Object.assign({proof: this.imageUrl}, values);
                        service.post(service.uri.pay.orderMainPay, {
                            amount: this.money,
                            pay_type: this.payType,
                            bank_data: data
                        }).then(res => {
                            if (res.code == 200) {
                                this.$message.success("提交成功");
                                this.showRechargeModal = false;
                                this.showRechargeModal2 = false;
                            }
                        });
                    }
                });
            },
            changeRangeDate(dates, dateStrings) {
                console.log(dates, dateStrings)
            },
            showDetail(id) {
                console.log(id);
                this.showTradeDetail = true;
            },
            goRecharge() {
                if (this.money <= 0) {
                    this.$message.error('充值不能低于最低充值金额');
                    return false;
                }
                this.showRechargeModal2 = true;
            },
            uploadChange(info) {
                if (info.file.status === 'uploading') {
                    this.uploadLoading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    console.log('image info:', info)
                    if (info.file.response.code == 1) {

                        this.imageUrl = info.file.response.data.url;
                    }
                    // Get this url from response in real world.
                    // getBase64(info.file.originFileObj, imageUrl => {
                    //     this.imageUrl = imageUrl;
                    //     this.uploadLoading = false;
                    // });
                }
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJpgOrPng && isLt2M;
            },
            getQRcode() {
                QRCode.toDataURL(this.QRlink, {errorCorrectionLevel: 'L', margin: 2, width: 128}, (err, url) => {
                    if (err) throw err
                    this.QRImgUrl = url
                })
            },
        }
    }
</script>

<style lang = "less" scoped>

    .row-info {
        display: flex;
        color: #de0000;
        align-items: center;

        .col-total {
            display: flex;
            align-items: flex-end;
            font-weight: 700;

            i {
                font-style: normal;
                display: block;
                line-height: 24px;
            }

            span {
                font-size: 24px;
                display: block;
                padding-left: 3px;
                line-height: 34px;
            }
        }

        .col-btn {
            margin-left: 20px;
        }
    }

    .recharge {
        label {
            color: #666;
            font-size: 14px;
            padding-right: 12px;
            padding-left: 12px;
        }

        .ant-input-number {
            width: 380px;
            height: 36px;
        }

        span {
            padding-left: 10px;
            color: #999;
        }
    }

    .recharge-btn {
        margin-top: 40px;
        text-align: center;
    }

    .title {
        padding: 32px 0 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
    }

    .icon {
        font-family: HelveticaNeue-Medium, HelveticaNeue;
        font-weight: 500;
        color: @primary-color;
        font-size: 18px;
    }

    .money {
        font-family: HelveticaNeue-Medium, HelveticaNeue;
        font-weight: 500;
        color: @primary-color;
        font-size: 28px;
    }

    .pay-box {
        background: #f7f7f7;
        padding: 20px 40px;

        .paychannel-title {
            margin-bottom: 15px;
        }

        .paychannel-box {
            overflow: hidden;

            .paychannel-item {
                float: left;
                width: 350px;
                height: 50px;
                font-size: 14px;
                color: #333;
                padding: 0 10px;
                line-height: 50px;
                background: #fff;
                border: 1px solid #e7e7e7;
                position: relative;
                margin-right: 30px;
                margin-bottom: 10px;

                img {
                    margin-right: 9px;
                }

                span {
                    vertical-align: middle;
                }
            }
        }
    }

    .ant-radio-wrapper {
        height: 20px;
        line-height: 20px;
        position: absolute;
        right: 0;
        top: 15px;
    }

    .bankInfo {
        border: 1px solid #e7e7e7;
        padding: 20px 30px;
        color: #666;
        font-size: 12px;

        .bank-title {
            color: #999;
        }

        .bank-number {
            color: #333;
            font-size: 18px;
        }

        .copy {
            cursor: pointer;
            width: 32px;
            height: 20px;
            background: #fff;
            padding: 4px;
            border: 1px solid #ededed;
        }
    }

    .banktip {
        height: 61px;
        background: #fff9e6;
        border: 1px solid #fbc300;
        color: #666;
        font-size: 12px;
        line-height: 61px;

        .scan-icon {
            float: left;
            width: 24px;
            margin-top: 10px;
        }
    }

    .bank-form {
        border: 1px solid #e7e7e7;
        padding: 20px 30px;
    }

    .modal-footer {
        margin: 24px -24px -24px;
        padding: 10px 16px;
        text-align: right;
        border-top: 1px solid #f0f0f0;
        border-radius: 0 0 2px 2px;
    }

    .scan-tip {
        width: 200px;
        background-color: #ebf1ff;
        color: #333;
        padding: 10px 20px;
    }

    .balance {
        background: #f7f7f7;
        padding: 9px 12px;
        color: #333;
        /*margin: 33px 100px 20px 80px;*/
        width: 380px;
    }
    .recharge{
        margin:20px 0;
    }

</style>